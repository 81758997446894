<template>
    <div>
        <ValidationObserver ref="form1" slim>
            <form @submit.prevent="login" novalidate="novalidate">

                <b-alert variant="danger" show v-if="error">
                    {{ error }}
                </b-alert>

                <ValidationProvider rules="required|email" v-slot="{ errors }">
                    <div class="mb-3">
                        <label>E-mail</label>
                        <b-form-input v-model="form.username" placeholder="mijn@emailadres.nl" :class="errors.length > 0 ? 'is-invalid' : null" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>

                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label>Wachtwoord</label>
                        <b-form-input type="password" v-model="form.password" placeholder="●●●●●●●●●" :class="errors.length > 0 ? 'is-invalid' : null" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                        <router-link to="/account/recovery" class="link-primary font-weight-bolder mt-2 d-block">
                            Wachtwoord vergeten?
                        </router-link>
                    </div>
                </ValidationProvider>

                <!--right::Input group-->
                <!--begin::Actions-->
                <div class="text-center">
                    <!--begin::Submit button-->
                    <b-button variant="primary" type="submit" block>
                        Inloggen
                    </b-button>
                    <!--right::Submit button-->
                </div>
                <!--right::Actions-->
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default ({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        props: {
            url: {
                type: [Boolean, String],
                default: '/manage',
            }
        },
        data() {
            return {
                error: '',
                session: {},
                form: {
                    username: '',
                    password: '',
                }
            }
        },
        methods: {
            async login() {

                this.error = '';

                let check = await this.$refs['form1'].validate();

                if (!check) {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                    return;
                }

                this.$axios.post("https://api.tixgo.nl/accounts/login", this.form)
                    .then( response => {
                        if (response.data.status == 'success') {
                            localStorage.setItem('type', response.data.session.type);
                            localStorage.setItem('token', response.data.session.token);
                            localStorage.setItem('role', response.data.session.role);
                            
                            this.$axios.defaults.headers.common = {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            };

                            this.$router.push(this.url);

                        } else {
                            this.error = 'Uw gebruikersnaam of wachtwoord onjuist.';
                        }
                    }
                );
            }
        }
    });
</script>

<style scoped>
    .small-card{
        margin:auto;
    }

    @media screen and (min-width:576px) {
        .small-card{
            max-width:500px;
        }
    }
</style>
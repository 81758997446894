<template>
    <div>
        <b-container class="pt-5 pb-5">
            <div class="text-center">
                <img alt="Tixgo" src="../assets/logo.svg" class="mb-4" width="120" />
            </div>
            <b-card no-body class="small-card">
                <b-card-body>
                    <div class="text-center mb-4">
                        <h1 class="fs-light">Inloggen</h1>
                        <div>
                            Nieuwe klant?
                            <router-link to="/account/register" class="link-primary font-weight-bolder">
                                Maak een Account
                            </router-link>
                        </div>
                    </div>
                    <LoginForm />
                </b-card-body>
            </b-card>
        </b-container>
    </div>
</template>

<script>
    import LoginForm from '@/components/LoginForm'

    export default ({
        components: {
            LoginForm,
        },
        metaInfo: {
            title: 'Inloggen',
        },
        data() {
            return {}
        },
        methods: {}
    });
</script>

<style scoped>
    .small-card{
        margin:auto;
    }
    

    @media screen and (min-width:576px) {
        .small-card{
            max-width:500px;
        }
    }
</style>